.App {
    text-align: center;
    background: var(--color-background);
  }
  .cursor-dot,
  .cursor-dot-outline {
    z-index: 9000;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 1;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .cursor-dot {
    width: 8px;
    height: 8px;
    background-color: #dcc356;
  }
  .cursor-dot-outline {
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: 1px solid #dcc356;
  }
  #theme-button3 {
    position: relative;
  }
  #theme-button3 input {
    position: absolute;
    left: 50px;
    top: 90px;
  }
  
  .load_app {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 130px !important;
    flex-direction: column;
  }
  