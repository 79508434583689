.services {
  color: #dce1eb;
}

.my-services {
  margin-bottom: 10px;
  position: relative;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  border-radius: 10px;
  padding: 10px 10px;
  display: flex;
  margin: 0.5rem;
  height: 350px;
  min-height: 350px;
  max-height: 350px;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  text-align: center;

  transition: all 0.5s ease;
  box-shadow: 2px 2px 2px 4px #0d012c;
}
.serviceCardWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
