.p {
  font-size: 0.825rem;
  line-height: 1.8rem;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin: 0rem 0 2rem 0;
  font-style: normal;
}

@media screen and (min-width: 768px) {
  .p {}
}

@media screen and (min-width: 1024px) {
  .p {
    line-height: 1.9rem;
  }
}

@media screen and (min-width: 1440px) {

  .p {
    font-size: 1.125rem;
  }
}