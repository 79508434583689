 @import url("../src/utils/utils.css");


@font-face {
  font-family: Nunito;
  src: url(../src/assets/fonts/Nunito-VariableFont_wght.ttf);
}


@font-face {
  font-family: 'Nunito Italic';
  src: url(../src/assets/fonts/Nunito-Italic-VariableFont_wght.ttf);
}

:root {
  --primary-color: #201F4F;
  --secondary-color: #EB8145;
  --tertiary-color: #b4b4b4;
  --white-color: #fff;
  --foundation-color: #0D0D21;
  --success-color: #5cb85c;
  --danger-color: #dc3545;
  --warning-color: #f3bb1c;
  --text-color: #babac8;
  --gray-text-color: #4d4c72;
  --grey-text-color: #909091;
  --scrollbarBG: var(--primary-color);
  --thumbBG: var(--secondary-color);
}


body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--white-color);
  background-color: var(--foundation-color);
  overflow-x: hidden;
  scroll-behavior: smooth;
  isolation: isolate;
}

body::-webkit-scrollbar {
  width: 11px;
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 

