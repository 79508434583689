.title {
  font-family: "DM Serif Display", sans-serif;
  display: block;
  font-size: 2rem;
  font-weight: 400;
  margin-top: 1rem;
  letter-spacing: -0.02em;
  line-height: 100%;
}

@media (min-width: 768px) {
  .title {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .title {
    font-size: 2.5rem;
  }
}

@media (min-width: 1440px) {
  .title {
    font-size: 2.925rem;
  }
}