


.job-title{
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 1px;
 
    font-weight: 800;
    color: greenyellow;

  
    font-family: "Nunito", sans-serif;
}