.footer {
  background: #14042b;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center
}

.footer-link {
  margin-top: 12px;
}

.footer-col {
  margin: 2rem 0
}

.footer-col-middle {
  margin: 0 1rem;
}

.footer-link>a,
a.footer-link {
  text-decoration: none;
  color: var(--white-color);
}

.colBold {
  font-family: 'Nunito', sans-serif;
  font-weight: 800;
  font-size: 24px;
  line-height: 140%;
  color: var(--white-color);
  flex-grow: 0;
}

.colNormal {
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 140%;
  color: var(--white-color);
  flex-grow: 0;
}

.copyright {
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--tertiary-color);
}

.icons {
  display: flex;
  gap: 60px;
  margin-top: 1rem;
}

.hr {
  height: 1px;
  min-width: fit-content;
  background-color: var(--tertiary-color);
  border: 1px solid var(--tertiary-color);
  margin: 2rem 0;
}

.contact-details {
  font-family: 'Nunito';
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: var(--white-color);
}

.contact-details-1 {
  font-family: 'Nunito';
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: var(--white-color);
  align-self: stretch
}

.col-3-details {
  font-family: "Nunito";
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: var(--white-color);
}