.img-div {
  width: 100px;

  cursor: pointer;
  height: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(23, 32, 61);
  box-shadow: 0px 3px 5px rgb(16 70 197 / 20%);
}

.skill-div{
    background: #14143a;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    padding: 10px 0;
    align-items: center;
    flex-direction: column;

    border: 1px solid rgba(146, 146, 165, 0.1);
}